import { Checkbox, FormControlLabel } from "@material-ui/core"

import { useTranslate } from "src/i18n/useTranslate"
import { MText } from "src/ui/MText"

export function MarketingConsentCheckbox({
  checked,
  onChecked,
}: {
  checked: boolean
  onChecked: (checked: boolean) => void
}) {
  const { t, langKeys } = useTranslate()
  return (
    <FormControlLabel
      control={
        <Checkbox
          name="ConsentBox"
          checked={checked}
          onChange={(e, checked) => onChecked(checked)}
        />
      }
      label={
        <MText variant="bodyS" color="secondary" textAlign="left">
          {t(langKeys.marketing_consent)}{" "}
        </MText>
      }
    />
  )
}
