import styled from "styled-components"

import { Checkbox, FormControlLabel } from "@material-ui/core"

import {
  HREF_MINUT_PRIVACY_POLICY,
  HREF_MINUT_TERMS_OF_SERVICE,
} from "src/constants/hrefs"
import { useTranslate } from "src/i18n/useTranslate"
import { ExternalLink } from "src/ui/Link/ExternalLink"
import { MText } from "src/ui/MText"

export function TosCheckbox({
  checked,
  onChecked,
}: {
  checked: boolean
  onChecked: (checked: boolean) => void
}) {
  const { Trans, t, langKeys } = useTranslate()
  function handleChange(
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) {
    onChecked(checked)
  }

  return (
    <TosBox
      value="start"
      control={
        <Checkbox
          checked={checked}
          onChange={handleChange}
          name="TOS accepted"
          color="primary"
        />
      }
      label={
        <MText variant="bodyS" color="secondary" textAlign="left">
          <Trans
            i18nKey={langKeys.accept_terms_of_service_web}
            components={{
              tos: (
                <ExternalLink href={HREF_MINUT_TERMS_OF_SERVICE}>
                  {t(langKeys.terms_of_service)}
                </ExternalLink>
              ),
              privacy: (
                <ExternalLink href={HREF_MINUT_PRIVACY_POLICY}>
                  {t(langKeys.privacy_policy)}
                </ExternalLink>
              ),
            }}
          />
        </MText>
      }
    />
  )
}

const TosBox = styled(FormControlLabel)`
  align-items: flex-start;
`
